<template>
    <div class="serviceCmmodityAdd">
        <div class="createStepProgress">
            <div class="step-num">
                <span class="active">1</span>
                <span>创建客服商品</span>
            </div>
            <img class="step-wire" :src="active > 1 ? require('./../../../assets/images/serviceData/blue.png') : require('./../../../assets/images/serviceData/xian.png')" alt="">
            <div class="step-num">
                <span :class="active > 1 ? 'active' : ''">2</span>
                <span>创建成功</span>
            </div>
        </div>
        <div class="storeGoodsAdd-title" v-if="active !== 2">
            <span>填写商品信息</span>
        </div>
        <div class="storeGoodsAdd-middle-span" v-if="active !== 2">
            <span></span>
            <span>商品基本信息</span>
        </div>
        <div class="serviceCmmodityAdd-form" v-if="active !== 2">
            <el-form ref="form" :model="form" label-width="110px" :rules="rules">
                <el-form-item label="商品标题" prop="title" style="width: 100%">
                    <el-input style="width: 100%" v-model="form.title" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <div class="newLine">
                <el-form-item label="商品价格" prop="price" style="width: 500px">
                    <el-input style="width: 300px;" v-model="form.price" @change="priceChange" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="商品折扣价" prop="discount" style="width:500px">
                    <el-input style="width: 300px;" v-model="form.discount" @change="priceChange2" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                </el-form-item>
                </div>
                <el-form-item label="行业分类" prop="industryClassify" style="width: 100%">
                    <el-select v-model="form.industryClassify" placeholder="请选择" @change="selectIndustryClassify">
                        <el-option
                                v-for="item in industryOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="storeGoodsAdd-middle-span">
                    <span></span>
                    <span>图片</span>
                </div>
                <el-form-item label="商品图片" style="position:relative;width: 100%;margin-top: 10px">
                    <span style="position: absolute;left: -78px;color: red">*</span>
                    <div class="showPicture">
                        <p style="position: relative">商品展示图<b style="position: absolute;left: -10px;color: #FF0000;font-size: 14px">*</b></p>
                    <div class="upladImgBox" v-show="imgList.length !== 0">
                        <div class="upladImgBox-item" v-show="imgList.length > 0 " v-for="(item,index) in imgList" :key="index">
                            <i class="iconfont" @click="clearImg(index)">&#xe608;</i>
                            <img :src="item" alt="">
                        </div>
                    </div>
                    <div class="uploadList">
                        <div class="uploadBtn" onclick="imgInput.click()">
                            <i class="iconfont">&#xe77f;</i>
                            <span style="margin-top: 4px">{{imgList.length>0?'继续上传':'点击上传'}}</span>
                        </div>
                    </div>
                    <input
                            ref="modelInput"
                            multiple
                            type="file"
                            id="imgInput"
                            @change="uploadImg($event)"
                            style="display:none"
                            accept="image/jpeg,image/png,image/jpg"
                    />
                        <span class="hint">图片至少上传3张，仅支持jpg、jpeg、png格式</span>
                    </div>
                </el-form-item>
                <div class="storeGoodsAdd-middle-span">
                    <span></span>
                    <span style="position: relative">商品描述</span>
                </div>
                <el-form-item label="电脑版商品描述" style="position: relative;width: 100%;margin-top: 20px">
                    <span style="position: absolute;left: -120px;color: red">*</span>
                    <div id="editor-describePc"></div>
                </el-form-item>
                <el-form-item label="手机版商品描述" style="position: relative">
                    <span style="position: absolute;left: -120px;color: red">*</span>
                    <div id="editor-describePhone"></div>
                </el-form-item>
            </el-form>
            <div class="createStepProgress-hint">
                <span>1、每张宽度建议640像素，所有图片宽度一致；</span>
                <span>2、每张高度小于等于960像素；</span>
                <span>3、每张图片容量应小于3000KB；</span>
                <span>4、图片格式为：JPG\GIF\PNG；</span>
                <span>5、图片总张数建议大于5张，但不超过30张；</span>
                <span>6、为了更好的视觉呈现，图片上的文字字号建议不小于20号</span>
            </div>
        </div>
        <div class="createStepSucceed-icon" v-if="active === 2">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">创建成功！</p>
            </div>
        </div>
        <div class="createStepBottom" v-if="active === 2">
            <el-button class="btn-blue-line-all" @click="toList">返回</el-button>
        </div>
        <div class="createStepBottom" v-if="active !== 2">
            <el-button class="btn-blue-line-all" @click="toList">上一步</el-button>
            <el-button class="next-step" @click="toNext">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import WE from "./../../../utils/wEditor";
    export default {
        name: "serviceCmmodityAdd",
        data(){
            return {
                active:1,
                industryOptions:[],
                form:{
                    title:'',
                    price:'',
                    discount:'',
                    industryClassify:'',
                    describePc:'',
                    describePhone:''
                },
                categoryName:'',
                editorTextPc:null,
                editorTextPhone:null,
                imgList:[],
                uploadImgArr:[],
                rules:{
                    title:[
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                    ],
                    price:[
                        { required: true, message: '请输入商品价格', trigger: 'blur' },
                        { min: 0, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    discount:[
                        { required: true, message: '请输入商品折扣价', trigger: 'blur' },
                        { min: 0, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    industryClassify:[
                        { required: true, message: '请选择行业分类', trigger: ['change', 'blur'] }
                    ],
                    uploadImg:[
                        { required: true, message: '请上传商品图片', trigger: 'change'}
                    ]
                }
            }
        },
        mounted() {
            this.editorTextPc = new WE('#editor-describePc',this.$api.uploadImages, 'image[]');
            this.editorTextPhone = new WE('#editor-describePhone',this.$api.uploadImages, 'image[]');
            this.fromEdit();
        },
        created() {
            this.getCategoryList();
        },
        activated() {
            this.editorTextPc = new WE('#editor-describePc',this.$api.uploadImages, 'image[]');
            this.editorTextPhone = new WE('#editor-describePhone',this.$api.uploadImages, 'image[]');
            this.fromEdit();
        },
        methods: {
            fromEdit(){
                if(this.$route.query.res){
                    let data = JSON.parse(this.$route.query.res);
                    this.imgList = [];
                    this.uploadImgArr =[];
                    data.goods_images.map(item=>{
                        this.imgList.push(item.src);
                        this.uploadImgArr.push(item.url)
                    })
                    this.form.title = data.goods_name;
                    this.form.industryClassify = !data.category_id? null : data.category_id;
                    this.categoryName = data.category_name;
                    this.form.price = data.goods_price;
                    this.form.discount = data.goods_promotion_price;
                    this.editorTextPc.setWEHtml(data.goods_details_pc);
                    this.editorTextPhone.setWEHtml(data.goods_details_phone);
                    // data.goods_images.map(item=>{
                    //     this.imgList.push(item.src);
                    //     this.uploadImgArr.push(item.url)
                    // })
                } else {
                    this.form = {
                        title: '',
                        price: '',
                        discount: '',
                        industryClassify: '',
                    }
                    this.imgList = [];
                    this.uploadImgArr =[];
                    this.editorTextPc.setWEHtml('');
                    this.editorTextPhone.setWEHtml('');
                }
            },
            priceChange(val){
                if(val){
                    let dot = val.indexOf(".");
                    if(dot === 0){
                        this.$message.warning('输入格式错误，小数点不能位于第一位！');
                        this.form.price = ''
                        return
                    }
                    let list = val.split('');
                    let num = 0;
                    list.map(item=>{
                        if(item == '.'){
                            num++
                        }
                    })
                    if(num > 1){
                        this.$message.warning('输入格式错误，只能有一个小数点！');
                        this.form.price = ''
                        return;
                    }
                    this.form.price = Number(val).toFixed(2)
                }
            },
            priceChange2(val){
                if(val){
                    let dot = val.indexOf(".");
                    if(dot === 0){
                        this.$message.warning('输入格式错误，小数点不能位于第一位！');
                        this.form.discount = ''
                        return
                    }
                    let list = val.split('');
                    let num = 0;
                    list.map(item=>{
                        if(item == '.'){
                            num++
                        }
                    })
                    if(num > 1){
                        this.$message.warning('输入格式错误，只能有一个小数点！');
                        this.form.discount = ''
                        return;
                    }
                    this.form.discount = Number(val).toFixed(2)
                }
            },
            selectIndustryClassify(val){
                this.industryOptions.map(item=>{
                    if(item.id === val){
                        this.categoryName = item.name
                    }
                })
            },
            // 分类
            getCategoryList(){
              this.$http.axiosGetBy(this.$api.categoryList, {}, res=>{
                  if(res.code === 200){
                      this.industryOptions = res.data
                  } else {
                      this.$message(res.msg)
                  }
              })
            },
            toNext(){
                let editorTextPc = this.editorTextPc.getWEHtml();
                let editorTextPhone = this.editorTextPhone.getWEHtml()

                if(this.form.title === '' || !this.form.industryClassify || this.categoryName === '' || this.form.price === '' || this.form.discount === '' || this.editorTextPc.getWEHtml() == '<p><br></p>' || this.editorTextPhone.getWEHtml() === '<p><br></p>'){
                    this.$message.warning('必填项不能为空！');
                    return;
                }
                let formData = new FormData();
                if(this.$route.query.res){
                    let data = JSON.parse(this.$route.query.res);
                    let id = data.goods_id;
                    formData.append('goods_id', id);
                }
                formData.append('goods_name',this.form.title);
                formData.append('category_id',this.form.industryClassify);
                formData.append('category_name',this.categoryName);
                formData.append('goods_price',this.form.price);
                formData.append('goods_promotion_price',this.form.discount);
                formData.append('goods_details_pc',editorTextPc);
                formData.append('goods_details_phone',editorTextPhone);
                if(this.uploadImgArr.length < 3){
                    this.$message.warning('商品图片最少上传3张，最多上传5张！');
                    return
                }

                let uploadImgArr = JSON.stringify(this.uploadImgArr);
                formData.append("goods_images",uploadImgArr)
                // for(let i = 0; i < this.uploadImgArr.length; i++){
                //     formData.append("goods_images[]",this.uploadImgArr[i])
                // }
                this.$http.axiosPost(this.$api.saveGoods, formData, res=>{
                    if(res.code === 200){
                        this.active = 2;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
                // this.active = 2;
            },
            toList(){
                this.active = 1;
                this.$router.push({
                    path:'/admin/storecustomer',
                    query:{
                        activeName:'serviceGoodsAdminName'
                    }
                })
                this.$refs['form'].resetFields();
            },
            // 上传图片
            uploadImg(event){
                if(this.imgList.length === 5 || this.imgList.length + event.target.files.length > 5){
                    this.$message.warning('已超过图片数量限制，最多上传5张！');
                    return
                }
                // let imgName = event.target.files[0].name;
                let formData = new FormData();
                let files = event.target.files;
                for(let i = 0; i < files.length; i++){
                    let fileType = ['png','jpeg','jpg'];
                    if(!fileType.includes(files[i].name.split('.')[files[i].name.split('.').length - 1].toLowerCase())){
                        this.$message.warning('图片格式错误！');
                        return;
                    }
                    formData.append("image[]", files[i]);
                }
                // formData.append('image', this.uploadImgArr);
                this.$http.axiosPost(this.$api.moreUpload, formData, res=>{
                    if(res.code === 200){
                        this.$refs.modelInput.value = '';
                        let resData = res.data;
                        for(let i = 0; i< resData.length; i++){
                            this.imgList.push(resData[i].src);
                            this.uploadImgArr.push(resData[i].url);
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            clearImg(val) {
                this.imgList.splice(val, 1);
                this.uploadImgArr.splice(val,1);
                // console.log(this.imgList,)
                // console.log(this.uploadImgArr)
            },
        }
    }
</script>

<style scoped lang="scss">
    .showPicture{
        margin-top: 40px;
        padding: 20px;
        background: #F5F7F9;

    }
    .hint{
        display: block;
        margin-top: 20px;
        color: #999999;
        font-size: 14px;
        font-weight: 500;
    }
    .uploadBtn{
        margin-top: 20px;
        cursor: pointer;
        width: 71px;
        height: 71px;
        border: 1px solid #E5E5E5;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
            color: #999999;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
        }
        i{
            font-size: 18px;
            color: #BFBFBF;
            line-height: 1;
        }
    }
    .newLine{
        display: flex;
    }
    .storeGoodsAdd-title{
        color: #333333;
        font-weight: bold;
        font-size: 16px;
    }
    .storeGoodsAdd-middle-span{
        padding-top: 18px;
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        span:nth-child(1){
            width: 3px;
            height: 10px;
            background: #FF4D77;
            display: inline-block;
        }
        span:nth-child(2){
            margin-left: 10px;
        }
        .storeGoodsAdd-form{
            margin-top: 20px;
        }
    }
    .upladImgBox{
        /*padding-top: 20px;*/
        display: flex;
        .upladImgBoxTxt{
            position: absolute;
            left: 0;
            top: 0;
            font-size: 12px;
        }
        .upladImgBox-item{
            position: relative;
            margin-left: 20px;
            i{
                position: absolute;
                right:-8px;
                top: -18px;
                color: #d81e06;
                font-size: 12px;
                cursor: pointer;
            }
            img{
                width: 71px;
                height: 71px;
                border: 1px solid #E5E5E5;
            }
        }
        .upladImgBox-item:nth-child(1){
            margin-left: 0;
        }
    }
    #editor-describePc,#editor-describePhone{
        width: 800px;
    }
    /deep/.w-e-toolbar{
        height: 40px;
        line-height: 30px;
        font-size: 12px;
    }
    ::-webkit-scrollbar {

        width: 0px;

        height: 2px;

        background-color: #ccc;

    }
    .serviceCmmodityAdd{
        height: calc( 100vh - 210px);
        padding: 30px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        .createStepProgress{
            padding: 30px 0px 100px 0px;
            margin: 10px auto;
            display: flex;
            align-items: center;
            .step-num{
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                span:nth-child(1){
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    color: #FFFFFF;
                    background: #DCDCDC;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 48px;
                }
                .active{
                    background: #1122D8!important;
                }
                span:nth-child(2){
                    display: inline-block;
                    width: 100px;
                    text-align: center;
                    top: 68px;
                    /*left: 30%;*/
                    position: absolute;
                    font-size: 16px;
                    color: #333333;

                }
            }
            .step-wire{
                height: 14px;
                margin: 0 10px;
            }
        }
        .createStepSucceed-icon{
            .success-content {
                margin-top: 155px;
                text-align: center;
                .iconfont {
                    color: #1FD18C;
                    font-size: 52px;
                }
                .text {
                    font-size: 24px;
                    margin: 20px 0 0;
                    font-weight: bold;
                }
            }
            .btn{
                margin-top: 60px;
                text-align: center;
            }
        }
        .serviceCmmodityAdd-form{
            margin-top: 20px;
            .createStepProgress-hint{
                display: flex;
                flex-direction: column;
                padding-left: 140px;
                span{
                    font-size: 12px;
                    line-height: 24px;
                    /*color: ;*/
                }
            }
        }
        .createStepBottom{
            margin-top: 60px;
            text-align: center;
        }
    }
</style>